<template>
    <q-layout class="com-login" view="hHh lpR fFf">
        <q-page-container>
            <div style="padding:50px;text-align:center">
                <div style="display:inline-block;width:400px;text-align:left">
                    <div style="text-align:center">
                        <!--<img src="/image/admin/login-icon-157x71.png" >-->
                        <div class="ft20 my3 py3" style="height:42px">
                        </div>
                    </div>
                    <q-card>
                        <q-card-section>
                            <div class="ft20 text-center" >
                                <q-icon name="login" ></q-icon> 로그인
                            </div>
                        </q-card-section>
                        <q-separator></q-separator>
                        
                        <q-card-section >
                            <div class="text-center">
                                <div class="text-bold mb3">공부는 이렇게~</div>
                                <div class="q-mb-md" >재미난 코딩에 빠져 봅시다.</div>
                            </div>
                            <com-data-form :form="form" ref="data_form">
                                <template v-slot="props">
                                    <!--
                                    <q-input outlined placeholder="학원URL"
                                             :error="props.formError.host? true : false" :error-message="props.formError.host"
                                             v-model="form.host">
                                        <template v-slot:prepend>
                                          <q-icon name="dns" />
                                        </template>
                                    </q-input>-->
                                    <q-input outlined placeholder="아이디" 
                                            :error="(props.formError.user_name)?true:false" 
                                            :error-message="props.formError.user_name" 
                                            @keyup.enter="handle_login"
                                            v-model="form.user_name" >
                                        <template v-slot:prepend>
                                            <q-icon name="perm_identity"></q-icon>
                                        </template>
                                    </q-input>
                                    <q-input outlined placeholder="비밀번호" :type="is_password ? 'password':'text'" 
                                            :error="(props.formError.passwd)?true:false" 
                                            :error-message="props.formError.passwd" 
                                            @keyup.enter="handle_login"
                                            v-model="form.passwd" >
                                        <template v-slot:prepend>
                                            <q-icon name="lock" ></q-icon>
                                        </template>
                                        <template v-slot:append>
                                            <q-icon :name="is_password ? 'visibility_off':'visibility'"
                                                    class="cursor-pointer" @click="is_password=!is_password" ></q-icon>
                                        </template>
                                    </q-input>
                                    <q-space></q-space>
                                    <q-btn type="submit" color="positive" class="full-width" label="로그인"
                                            @click="handle_login" ></q-btn>
                                </template>
                            </com-data-form>
                        </q-card-section>
                        <q-separator ></q-separator>
                        
                        <q-card-section class="text-right">
                                아이디가 없다면, 관리자에 문의하세요.
                        </q-card-section>
                    </q-card>
                    
                </div>
            </div>
        </q-page-container>
    </q-layout>
</template>

<script>
import comDataForm from '@/components/comDataForm.vue';

export default {
    components : {
        comDataForm,
    },
    data : function() {
        return {
            form : {
                host : '',
                user_name : '',
                passwd : '',
            },
            is_password : true,
        }
    },
    computed :{
        center_info() {
            return this.$store.state.center_info;
        },
    },
    methods : {
        handle_login() {
            let vm = this;
            let host = this.form.host;
            let pattern = /^https?:\/\//i;
            host = host.replace(pattern, '');
            //server.py로 brower에서 작업할때 proxy 설정에서는 있으면 안됨.
            //2) android, ios에서는 아래줄을 활성화함.
            if(vm.$c.io.is_mobile) {
                vm.$c.io.host = 'https://'+host;
            }

            vm.$q.loading.show();
            vm.$refs.data_form.clear_error();
            vm.$c.io.post('/api/account/user/login_coding', this.form).then(function(response) {
                let data = response.data;
                if(data.success) {
                    window.localStorage.setItem('host', host);
                    window.localStorage.setItem('user_name', vm.form.user_name);
                    //window.localStorage.setItem('password', vm.form.passwd);
                    vm.$c.io.get('/api/account/user/info', {}).then(function(response) {
                        vm.$q.loading.hide();
                        let data = response.data;
                        if(data.success) {
                            vm.$store.state.user_id = data.user_prof.user_id;
                            vm.$store.state.user.a_name = data.user_prof.a_name;
                            vm.$store.state.user.user_group_id = data.user_prof.user_group_id;
                            vm.$store.state.user.user_id = data.user_prof.user_id;
                            vm.$store.state.user.user_name = data.user_prof.user_name;
                            vm.$c.goto_page('/user/app/school/contents/dashboard');
                        }
                    });
                } else {
                    vm.$q.loading.hide();
                    vm.$c.form.set_error(data.error, vm.$refs.data_form.formError);
                    if( data.message ) {
                        vm.$c.response_notify(0, data.message);
                    }
                }
            }).catch(function (error) {
                console.log(error);
                vm.$q.loading.hide();
                vm.$c.response_notify(0, '서버와 연결할 수 없습니다.<br/>1) 학원url이 올바른지 확인해주세요.<br/>2) 네트워크 접속상태를 확인해주세요.');
            });

        },
    },
    beforeMount() {
        let host = window.localStorage.getItem('host');
        let user_name = window.localStorage.getItem('user_name');
        //let passwd = window.localStorage.getItem('password');
        if(host) { this.form.host = host; }
        if(user_name) { this.form.user_name = user_name; }
        //if(passwd) { this.form.passwd = passwd; }
    }
}
</script>

