<template>
    <div>
        <com-login ></com-login>
        <com-loading></com-loading>
    </div>
</template>

<script>
import comLogin from './comLogin.vue'
import comLoading from '@/components/comLoading.vue'

export default {
    name: 'App',
    components: {
        comLogin,
        comLoading,
    },
    data() {
        return {
        }
    },
    methods : {
        load_center_info() {
            let vm = this;
            vm.$store.dispatch('loading', {name:'학원정보', loading:true});
            vm.$store.state.center_info = {};
            let url = `/api/app/aca_erp/center`;
            vm.$c.io.get(url, {}).then( function(response){
                var data = response.data;
                if(data['success']) {
                    //console.log(data);
                    vm.$store.state.center_info = data.center;
                    vm.$store.dispatch('loading', {name:'학원정보', loading:false});
                } 
            });
        },
    },
    
    created() {
        this.$c.$root = this;
    },
    beforeMount() {
        let vm = this;
        //학원정보
        vm.load_center_info();
    },
}
</script>

